import {
    AbstractControl,
    UntypedFormGroup,
    ValidationErrors,
} from '@angular/forms';
import { FormFieldType } from '@wdx/shared/utils';
import { DateTime } from 'luxon';

export class BespokeValidators {
    static getLuxonDate(date: string | Date) {
        return date instanceof Date
            ? DateTime.fromJSDate(date)
            : DateTime.fromISO(date);
    }

    static minArrayLength(
        min: number,
    ): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): { [key: string]: number } | null => {
            return !Array.isArray(control.value) || control.value?.length >= min
                ? null
                : { minArrayLength: min };
        };
    }

    static maxArrayLength(
        max: number,
    ): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): { [key: string]: number } | null => {
            if (!control.value) {
                return null;
            }

            return Array.isArray(control.value) && control.value.length <= max
                ? null
                : { maxArrayLength: max };
        };
    }

    static maxCurrencyAmount(
        max: number,
    ): (control: AbstractControl) => ValidationErrors | null {
        return (
            control: AbstractControl,
        ): { [key: string]: { max?: number } } | null => {
            return control.value?.amount > max ? { max: { max } } : null;
        };
    }

    static minCurrencyAmount(
        min: number,
    ): (control: AbstractControl) => ValidationErrors | null {
        return (
            control: AbstractControl,
        ): { [key: string]: { min?: number } } | null => {
            return control.value?.amount < min ? { min: { min } } : null;
        };
    }

    // This is DateRestrictionType.HistoricOnly
    static dateMustBeBefore(
        baseDate: string | Date,
        fieldType: FormFieldType,
    ): (control: AbstractControl) => ValidationErrors | null {
        return (
            control: AbstractControl,
        ): { [key: string]: boolean } | null => {
            if (!control.value) {
                return null;
            }

            const CONTROL_VALUE = control.value;
            const BASE_DATE = new Date(baseDate);

            /**
             * Adding 1 minute from the users time to ensure that the server date could be update to 1 minute less
             */
            let controlDateToMillis = new Date(CONTROL_VALUE).getTime();
            let baseDateToMillis = BASE_DATE.getTime() + 60 * 1000;
            const ERROR = { dateMustBeBefore: true };

            if (fieldType === FormFieldType.Date) {
                baseDateToMillis = this.getLuxonDate(BASE_DATE)
                    .startOf('day')
                    .toMillis();
                controlDateToMillis = this.getLuxonDate(CONTROL_VALUE)
                    .startOf('day')
                    .toMillis();
            }

            return controlDateToMillis <= baseDateToMillis ? null : ERROR;
        };
    }

    // This is DateRestrictionType.FutureOnly
    static dateMustBeAfter(
        baseDate: string | Date,
        fieldType: FormFieldType,
    ): (control: AbstractControl) => ValidationErrors | null {
        return (
            control: AbstractControl,
        ): { [key: string]: boolean } | null => {
            if (!control.value) {
                return null;
            }

            const CONTROL_VALUE = control.value;
            const BASE_DATE = new Date(baseDate);

            /**
             * Removing 1 minute from the users time to ensure that the server date could be update to 1 minute greater
             */
            let controlDateToMillis = new Date(CONTROL_VALUE).getTime();
            let baseDateToMillis = BASE_DATE.getTime() - 60 * 1000;
            const ERROR = { dateMustBeAfter: true };

            if (fieldType === FormFieldType.Date) {
                baseDateToMillis = this.getLuxonDate(BASE_DATE)
                    .startOf('day')
                    .toMillis();
                controlDateToMillis = this.getLuxonDate(CONTROL_VALUE)
                    .startOf('day')
                    .toMillis();
            }

            return controlDateToMillis >= baseDateToMillis ? null : ERROR;
        };
    }

    static datesMustBeValidRange(): (
        control: AbstractControl,
    ) => ValidationErrors | null {
        return (
            control: AbstractControl,
        ): { [key: string]: boolean } | null => {
            if (
                !control.value ||
                (!control?.value?.start && !control?.value?.end)
            ) {
                return null;
            }

            const start = control?.value?.start;
            const end = control?.value?.end;
            const START_CONTROL = control?.get('start');
            const END_CONTROL = control?.get('end');

            if (start && end) {
                if (new Date(start) <= new Date(end)) {
                    START_CONTROL?.setErrors(null);
                    END_CONTROL?.setErrors(null);

                    return null;
                }
            }

            START_CONTROL?.setErrors({ invalid: true });
            END_CONTROL?.setErrors({ invalid: true });

            return { datesMustBeValidRange: true };
        };
    }

    static regex(
        pattern: string,
        message?: string | null,
    ): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): { [key: string]: string } | null => {
            if (!control.value) {
                return null;
            }

            const matches = String(control.value).match(pattern);

            return matches ? null : { regex: message || pattern };
        };
    }

    static equalTo(
        formGroup: UntypedFormGroup,
        compareTo: string,
    ): (control: AbstractControl) => ValidationErrors | null {
        return (
            control: AbstractControl,
        ): { [key: string]: boolean } | null => {
            const controlToCompare = formGroup.controls[compareTo];
            return control.value === controlToCompare.value
                ? null
                : { equalTo: true };
        };
    }

    static mustContainRequiredFields(
        requiredKeys: string[],
    ): (control: AbstractControl) => ValidationErrors | null {
        return (
            control: AbstractControl,
        ): { [key: string]: boolean } | null => {
            if (!control.value) {
                return null;
            }
            const hasIncompleteFields = requiredKeys.some(
                (requiredKey) => !control.value[requiredKey],
            );
            return hasIncompleteFields
                ? { mustContainRequiredFields: true }
                : null;
        };
    }

    static isTrue(): (control: AbstractControl) => ValidationErrors | null {
        return (control: AbstractControl): { [key: string]: boolean } | null =>
            control.value ? null : { isTrue: true };
    }
}
