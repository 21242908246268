import { Injectable } from '@angular/core';
import {
    IdentityCheck,
    IdentityCheckCreate,
    IdentityCheckVerificationOptions,
} from '@wdx/clmi/clmi-swagger-gen';
import { Observable } from 'rxjs';
import { BaseApiService } from '../base-api.service';
import { IdentityAndVerificationUrlSegment } from './identity-and-verification-url-segment';

@Injectable()
export class IdentityAndVerificationService extends BaseApiService {
    getIdentityCheckForParty(partyId: string): Observable<IdentityCheck[]> {
        return this.get<IdentityCheck[]>(
            `${IdentityAndVerificationUrlSegment.IdentityCheck}/${IdentityAndVerificationUrlSegment.Party}/${partyId}`,
        );
    }

    getIdentityCheck(identitycheckId: string): Observable<IdentityCheck> {
        return this.get<IdentityCheck>(
            `${IdentityAndVerificationUrlSegment.IdentityCheck}/${identitycheckId}`,
        );
    }

    getIdentityCheckOptions(): Observable<IdentityCheckVerificationOptions> {
        return this.get<IdentityCheckVerificationOptions>(
            `${IdentityAndVerificationUrlSegment.IdentityCheck}/${IdentityAndVerificationUrlSegment.Options}`,
        );
    }

    shareWithClient(
        identitycheckId: string,
        clientId: string,
    ): Observable<any> {
        return this.patch<any>(
            `${IdentityAndVerificationUrlSegment.IdentityCheck}/${identitycheckId}/${IdentityAndVerificationUrlSegment.Action}/${IdentityAndVerificationUrlSegment.Share}?clientId=${clientId}`,
            {},
        );
    }

    createIdentityCheck(identityCheck: IdentityCheck) {
        return this.post<IdentityCheck, IdentityCheck>(
            `${IdentityAndVerificationUrlSegment.IdentityCheck}`,
            identityCheck,
        );
    }

    updateStatus(identitycheckId: string): Observable<IdentityCheck> {
        return this.patch<IdentityCheck>(
            `${IdentityAndVerificationUrlSegment.IdentityCheck}/${identitycheckId}/${IdentityAndVerificationUrlSegment.Status}/${IdentityAndVerificationUrlSegment.Submitted}`,
            {},
        );
    }
}
