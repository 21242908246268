/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

export enum DateRestrictionType {
  None = 'None',
  TodayOnly = 'TodayOnly',
  HistoricOnly = 'HistoricOnly',
  HistoricAndToday = 'HistoricAndToday',
  FutureOnly = 'FutureOnly',
  FutureAndToday = 'FutureAndToday'
}
