import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { IdentityAndVerificationService } from '@wdx/clmi/api-services/services';
import * as identityAndVerificationActions from './identity-and-verification.actions';

@Injectable()
export class IdentityAndVerificationEffects {
    private actions$ = inject(Actions);
    private identityAndVerificationService = inject(
        IdentityAndVerificationService,
    );

    getIdentityCheckForParty$ = createEffect(() =>
        this.actions$.pipe(
            ofType(identityAndVerificationActions.getIdentityCheckForParty),
            mergeMap((action) =>
                this.identityAndVerificationService
                    .getIdentityCheckForParty(action.partyId)
                    .pipe(
                        map((identityCheck) =>
                            identityAndVerificationActions.getIdentityCheckForPartySuccess(
                                {
                                    identityCheck,
                                    partyId: action.partyId,
                                },
                            ),
                        ),
                        catchError((error) =>
                            of(
                                identityAndVerificationActions.getIdentityCheckForPartyError(
                                    {
                                        error,
                                        partyId: action.partyId,
                                    },
                                ),
                            ),
                        ),
                    ),
            ),
        ),
    );
    getIdentityCheck$ = createEffect(() =>
        this.actions$.pipe(
            ofType(identityAndVerificationActions.getIdentityCheck),
            mergeMap((action) =>
                this.identityAndVerificationService
                    .getIdentityCheck(action.identitycheckId)
                    .pipe(
                        map((identityCheck) =>
                            identityAndVerificationActions.getIdentityCheckSuccess(
                                {
                                    identityCheck,
                                    identitycheckId: action.identitycheckId,
                                },
                            ),
                        ),
                        catchError((error) =>
                            of(
                                identityAndVerificationActions.getIdentityCheckError(
                                    {
                                        error,
                                        identitycheckId: action.identitycheckId,
                                    },
                                ),
                            ),
                        ),
                    ),
            ),
        ),
    );

    getIdentityCheckOptions$ = createEffect(() =>
        this.actions$.pipe(
            ofType(identityAndVerificationActions.getIdentityCheckOptions),
            mergeMap(() =>
                this.identityAndVerificationService
                    .getIdentityCheckOptions()
                    .pipe(
                        map((options) =>
                            identityAndVerificationActions.getIdentityCheckOptionsSuccess(
                                {
                                    options,
                                },
                            ),
                        ),
                        catchError((error) =>
                            of(
                                identityAndVerificationActions.getIdentityCheckOptionsError(
                                    {
                                        error,
                                    },
                                ),
                            ),
                        ),
                    ),
            ),
        ),
    );

    shareWithClient$ = createEffect(() =>
        this.actions$.pipe(
            ofType(identityAndVerificationActions.shareIdCheckWithClient),
            mergeMap((action) =>
                this.identityAndVerificationService
                    .shareWithClient(action.identitycheckId, action.clientId)
                    .pipe(
                        map(() => {
                            return identityAndVerificationActions.shareIdCheckWithClientSuccess(
                                {
                                    identitycheckId: action.identitycheckId,
                                    clientId: action.clientId,
                                },
                            );
                        }),
                        catchError((error: Error) =>
                            of(
                                identityAndVerificationActions.shareIdCheckWithClientFailure(
                                    {
                                        error,
                                    },
                                ),
                            ),
                        ),
                    ),
            ),
        ),
    );
}
